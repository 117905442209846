



















//import Vue from 'vue';
import { Vue, Component } from "vue-property-decorator";
@Component({
        data: () => ({
        })
    })
export default class PgaAccessDenied extends Vue {}
